// src/Login.js

import React, { useState, useEffect } from 'react';

function LoginForm() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [showPasswordInput, setShowPasswordInput] = useState(false);
  const [message, setMessage] = useState("");

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
    setIsEmailValid(true);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setIsPasswordValid(true);
  };

  const handleNextClick = async () => {
    // Validate email using an external API here
    const isEmailValidFromAPI = await validateEmailAgainstAPI(email);

    if (isEmailValidFromAPI) {
      setShowPasswordInput(true);
    } else {
      setIsEmailValid(false);
    }
  };

  const handleSubmit = async () => {
    // Validate username and password using an external API here
    const isCredentialsValid = await validateCredentialsAgainstAPI(email, password);

    if (isCredentialsValid) {
      // Perform your desired action upon successful validation
      console.log('Credentials are valid');
    } else {
      setIsPasswordValid(false);
    }
  };

  const [data, setData] = React.useState(null);

  React.useEffect(() => {
    fetch("/api/test")
      .then((res) => res.json())
      .then((data) => setMessage(process.env.REACT_APP_TEST_MESSAGE));
  }, []);

  return (
    <div>
      <h2>Login Form ({message})</h2>
      <div>
        <label>Email Address:</label>
        <input type="email" value={email} onChange={handleEmailChange} />
        {!isEmailValid && <p className="error">Invalid email address</p>}
        {!showPasswordInput && <button onClick={handleNextClick}>Next</button>}
      </div>
      {showPasswordInput && (
        <div>
          <label>Password:</label>
          <input type="password" value={password} onChange={handlePasswordChange} />
          {!isPasswordValid && <p className="error">Invalid password</p>}
          <button onClick={handleSubmit}>Submit</button>
        </div>
      )}
    </div>
  );
}

// Replace these mock functions with actual API calls
async function validateEmailAgainstAPI(email) {
  // Simulate API call and validation
  return email.includes('@');
}

async function validateCredentialsAgainstAPI(email, password) {
  // Simulate API call and validation
  return email === 'valid@email.com' && password === 'password123';
}

export default LoginForm;
